<template>
  <BaseLayout>
    <template #header>
      <h2>Pelabelan dan Penimbangan</h2>
      <div class="d-flex justify-content-between">
        <div class="row align-items-center">
          <p class="hp-p1-body mb-0 mx-3 text-black">
            Semua ({{ table.total }})
          </p>
          <router-link to="/lab/processing/labeling-and-weighing">
            <b-button variant="primary-2"> Tambah Pengolahan </b-button>
          </router-link>
        </div>
        <div class="row no-gutters w-15">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
              >
                <i class="ri-search-line remix-icon text-primary" />
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              v-model="table.search"
              type="search"
              placeholder="Search"
              class="border-left-0"
              @input="debounceSearch"
            />
          </b-input-group>
        </div>
      </div>
      <div class="d-flex mt-3">
        <b-form-group
          label="Filter Berdasarkan Komponen"
          label-for="filter-component"
        >
          <b-form-select
            id="filter-component"
            v-model="filter.component"
            class="mr-5"
            :options="options.componentOptions"
            @change="handleComponentInput"
          />
        </b-form-group>
        <b-form-group
          label="Filter Berdasarkan status"
          label-for="filter-status"
          class="ml-3"
        >
          <b-form-select
            id="filter-status"
            v-model="filter.status"
            :options="options.statusOptions"
            @change="handleStatusInput"
          />
        </b-form-group>
      </div>
    </template>
    <template #content>
      <b-table
        style="overflow: auto; white-space: nowrap"
        :items="table.items"
        :fields="fields"
        :busy.sync="table.loading"
        responsive="sm"
        show-empty
      >
        <template v-slot:cell(Aksi)="data">
          <b-button
            v-if="data.item.status === 'success'"
            variant="warning-1"
            @click="downloadBarcode(data.index)"
          >
            <i class="ri-menu-fill mr-1" />
            Cetak Barcode
          </b-button>
        </template>
        <template #table-busy>
          <div class="hp-p1-body text-center my-2">
            <b-spinner class="align-middle mr-8" />
            <strong>Memuat...</strong>
          </div>
        </template>
        <template v-slot:cell(blood_type)="row">
          {{ getBloodType(row.item.blood_type) }}
        </template>
        <template #cell(status)="row">
          <b-badge :variant="getStatusVariant(row.item.status)">
            {{ row.item.status === "success" ? "Berhasil" : "Gagal" }}
          </b-badge>
        </template>
      </b-table>
      <b-pagination
        v-model="table.currentPage"
        class="mb-3"
        align="right"
        :per-page="table.perPage"
        :total-rows="table.total"
        @input="changePage"
      />
    </template>
  </BaseLayout>
</template>

<script>
import {
  BButton,
  BInputGroup,
  BFormGroup,
  BInputGroupPrepend,
  BFormInput,
  BSpinner,
  BPagination,
  BTable,
  BBadge,
  BFormSelect,
} from "bootstrap-vue";
import tableDataMixins from "@/mixins/tableDataMixins";
import manageBloodProcessAPI from "@/api/blood_process/manageBloodProcessAPI";
import JsBarcode from "jsbarcode";

export default {
  name: "ListBloodResults",
  components: {
    BTable,
    BPagination,
    BSpinner,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BBadge,
    BFormSelect,
    BFormGroup,
  },
  mixins: [tableDataMixins],
  data() {
    return {
      fields: [
        {
          label: "No",
          key: "id",
          sortable: true,
        },
        {
          label: "Nomor Kantong Utama",
          key: "main_barcode",
          sortable: true,
        },
        {
          label: "Nomor Kantong Hasil Olah",
          key: "component_barcode",
        },
        {
          label: "Gol. Darah",
          key: "blood_type",
        },
        {
          label: "Volume",
          key: "volume",
        },
        {
          label: "Berat",
          key: "weight",
        },
        {
          label: "Status",
          key: "status",
        },
        {
          label: "Alasan Gagal",
          key: "reason_failed",
        },
        {
          key: "Aksi",
          sortable: false,
        },
      ],
      filter: {
        status: "",
        component: "",
      },
      selectedBy: {
        status: null,
        component: null,
      },
      options: {
        statusOptions: [
          { value: "", text: "Pilih Opsi Status" },
          { value: "success", text: "Berhasil" },
          { value: "failed", text: "Gagal" },
        ],
        componentOptions: [
          { value: "", text: "Pilih Jenis Komponen" },
          { value: "PRC", text: "PRC" },
          { value: "LP", text: "LP" },
          { value: "TC", text: "TC" },
        ],
      },
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    // helpers methods untuk konversi data golongan darah
    getBloodType(bloodTypeId) {
      const bloodTypes = {
        "A+": "A Pos",
        "A-": "A Neg",
        "B+": "B Pos",
        "B-": "B Neg",
        "AB+": "AB Pos",
        "AB-": "AB Neg",
        "O+": "O Pos",
        "O-": "O Neg",
      };
      return bloodTypes[bloodTypeId];
    },
    // untuk variants status dengan tampilan badge
    getStatusVariant(status) {
      switch (status.toLowerCase()) {
        case "success":
          return "success";
        default:
          return "danger";
      }
    },
    // fungsi untuk menampilkan data dari remote api
    async fetchData() {
      try {
        this.table.loading = true;

        const params = {
          page: this.table.currentPage,
          per_page: this.table.perPage,
          search: this.table.search,
          component: this.selectedBy.component
            ? this.selectedBy.component
            : undefined,
          status: this.selectedBy.status ? this.selectedBy.status : undefined,
        };

        const { data: response } = await manageBloodProcessAPI.getAll({
          params,
        });

        this.table.items = response.data.data;
        this.table.total = response.data.total;
      } catch (e) {
        console.error(e);
      } finally {
        this.table.loading = false;
      }
    },
    // fungsi untuk paginations dan search
    changePage(page) {
      this.table.currentPage = page;
      this.fetchData();
    },
    debounceSearch() {
      clearTimeout(this.table.debounceTimer);
      this.table.debounceSearch = setTimeout(() => {
        this.onChangeSearchTable();
      }, 500);
    },
    onChangeSearchTable() {
      this.fetchData();
    },
    // menangani fitur filtering komponen
    handleComponentInput(selectedValue) {
      this.selectedBy.component = selectedValue;

      this.fetchData();
    },
    // menangani fitur filtering status
    handleStatusInput(selectedValue) {
      this.selectedBy.status = selectedValue;

      this.fetchData();
    },
    // download barcodes per row index data using js barcode
    async downloadBarcode(index) {
      // Get the data for the current row from the table
      const data = this.table.items[index];

      // Barcode settings
      const barcodeSettings = {
        width: 3,
        fontSize: 16,
        font: "Arial",
        fontOptions: "bold",
        format: "CODE128",
        margin: 20,
        displayValue: true,
      };

      // Create a temporary canvas element to render the barcode
      const canvas = document.createElement("canvas");

      // Render the barcode into the canvas using JsBarcode
      JsBarcode(canvas, data.component_barcode, barcodeSettings);

      // Convert canvas to image (PNG) data URL
      const dataURL = canvas.toDataURL("image/png");

      // Create an anchor tag to trigger the download
      const a = document.createElement("a");
      a.href = dataURL;
      a.download = `barcode_${data.component_barcode}_33x15mm_600dpi.png`;
      a.click();
    },
  },
};
</script>
