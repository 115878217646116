import API from '../services/services'

class ManageBloodProcessAPI {
  checkingBarcode(payload) {
    return API.post('aftap_examination/cheking_barcode', payload)
  }

  storeBloodProcess(payload) {
    return API.post('blood_component_result/store', payload)
  }

  getAll(params) {
    return API.get('blood_component_result/list', params)
  }

  trackingBloodProcess(params) {
    return API.get(`blood_processing/track/${params}`)
  }
}

export default new ManageBloodProcessAPI()
